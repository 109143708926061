<template>
 <div class="s_user_cash_out_export_pop">
    <el-dialog :title="'导出垫付订单列表'" class="s-user-cash-out-dialog" top="15vh" width="800px" :close-on-click-modal="false" :visible.sync="show">
      <el-form :model="formData" label-width="140px" size="mini">
        <el-row>
          <el-col :span="12">
              <el-form-item label="导出任务状态：">
                <el-select v-model="formData.status" placeholder="请选择">
                  <el-option v-for="item in options"  :key="item.value" :label="item.title" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
              <el-form-item label="导出平台类型：">
                <el-select v-model="formData.platform_id" placeholder="请选择">
                  <el-option v-for="item in platFormList"  :key="item.id" :label="item.title" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
              <el-form-item label="导出日期范围：">
                <el-date-picker v-model="formData.date_time" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
              </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
              <el-form-item label="导出店铺ID：">
                <el-input v-model="formData.user_shop_id" placeholder="请输入导出店铺ID"></el-input>
              </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
              <el-form-item label="导出任务编号：">
                <el-input v-model="formData.user_task_id" placeholder="请输入任务编号"></el-input>
              </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="show = false" >取 消</el-button>
        <el-button type="primary" @click="apiExportTaskOrderAdminHandel">确定导出</el-button>
      </div>
    </el-dialog>  
 </div>
</template>

<script>
import { apiExportTaskOrderAdmin } from '@/utils/api.js'
export default {
  props: {
    platFormList: {
      type: Array,
      default() {
        return  [ { id: 'ALL', title: '全部'} ]
      }
    }
  },
  data() {
    return {
      show: false,
      value1: [],
      formData: {
        status: 'ALL',
        platform_id: 'ALL',
        date_time: [],
        user_task_id: '',
        user_shop_id: ''
      },
      options: [
        { title: '全部', value: 'ALL' },
        { title: '待接单', value: '10' },
        { title: '待审核', value: '20' },
        { title: '操作中', value: '30' },
        { title: '待返款', value: '40' },
        { title: '待收货', value: '50' },
        { title: '待完成', value: '60' },
        { title: '已完成', value: '70' },
        { title: '已取消', value: '0' },
      ],
    }
  },
  methods: {
    onShow() {
      this.show = true
    },
    onClose() {
      this.show = false
    },
    // 确认导出
    async apiExportTaskOrderAdminHandel() {
      let loading = this.$loading()
      const { success, data } = await apiExportTaskOrderAdmin(this.formData).catch(() => loading.close())
      loading.close()
      if(!success) return
      let a = document.createElement('a')
      a.setAttribute('href', data.filePath)
      a.setAttribute('download', "")
      a.click()
      console.log(data)
    },
  }
}
</script>

<style lang='scss'>

</style>